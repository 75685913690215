<template lang='pug'>
aside.contacts
  .contacts__cont.cont
    h1.contacts__caption.caption Контакты
    .contacts__address
      span.contacts__note Адрес
      span.contacts__address-value.title {{fullAddress}}
      a.contacts__address-link.link_white(
          href="https://yandex.ru/maps/?rtext=~60.71623856%2C28.72369",
          target="_blank"
        ) Посторить маршрут на Яндекс Картах
    .contacts__worktime
      span.contacts__worktime-value(v-if="worktime.weekdays" v-html="worktime.weekdays" )
      //span.contacts__worktime-value(v-if="worktime.weekends") {{worktime.weekends}}
      span.contacts__worktime-value Вход свободный
    .contacts__mail
      span.contacts__note Почта
      a.contacts__mail-value.title.link-contact(:href="mail.link") {{mail.mask}}
    .contacts__phone
      span.contacts__note Телефон
      a.contacts__phone-value.title.link-contact(:href="phone.link") {{phone.mask}}
    .contacts__social
      span.contacts__note Мы в соцсетях
      .contacts__social-list
          a.contacts__social-value(
            v-for="(item, i) in socials",
            :key="i"
            :class="`contacts__social-value_${item.mod}`",
            :href="item.link"
            target="_blank",
            rel="noopener nofollow",
            :title="item.name"
          )
    yandex-map.contacts__map(:coords="[60.71623856, 28.72369000]", :zoom="17", :controls="['zoomControl']", :behaviors="['drag', 'multiTouch', 'dblClickZoom']" )
      ymap-marker(:coords="[60.71623856, 28.72369000]", markerId="1")
</template>

<script>
import '@/assets/styles/components/contacts.sass'
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import { socials } from '../../assets/scripts/socials'
import { phone, worktime, mail, fullAddress } from '../../assets/scripts/variables'

export default {
  name: 'Contacts',
  components: { yandexMap, ymapMarker },
  data () {
    return {
      socials,
      phone,
      worktime,
      mail,
      fullAddress
    }
  }
}
</script>
