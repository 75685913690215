<template lang='pug'>
head-meta(
  title-page="Арт-пространство «Грот» — Музей «Шоколадный Выборг»",
  description="Шоколадное пиво, яблочный сидр, домашний квас, разнообразный шоколад и снеки также ждут Вас в гроте. Дегустируйте на месте, берите с собой для родных и друзей"
)
the-grot
contacts-line
</template>

<script>
import TheGrot from '../components/grot/TheGrot'
import HeadMeta from '../components/_layout/HeadMeta'
import Contacts from '../components/contacts/TheContacts'
import ContactsLine from '../components/_layout/TheContactsLine'

export default {
  name: 'GrotPage',
  components: {
    ContactsLine,
    Contacts,
    HeadMeta,
    TheGrot
  }
}
</script>
