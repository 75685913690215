<template lang='pug'>
article.grot
  .grot__cont.cont
    h1.grot__caption ГР
      picture.grot__caption-picture
        source(srcset="/images/grot/grot-1_74.webp 1x, /images/grot/grot-1_148.webp 2x", type="image/webp",  media="(max-width: 1240px)")
        source(srcset="/images/grot/grot-1_152.webp 1x, /images/grot/grot-1_228.webp 1.5x", type="image/webp")
        source(srcset="/images/grot/grot-1_152.png 1x, /images/grot/grot-1_228.png 1.5x")
        img.grot__caption-image(src="/images/grot/grot-1_152.png", alt="Грот - логотип", title="Грот - логотип", width="152", height="167")
      span.grot__caption-letter T
    p.grot__text-big.text-big
      | Если есть на свете МЕСТА СИЛЫ, то это безусловно одно из них
    p.grot__text-big.text-big
      | Эта рукотворно-нерукотворная пещера с обалденными сводчатыми потолками создавалась миллионами лет геологических
      | процессов и дорабатывалась солдатами Петра Первого отвоевавшими у гранитной скалы дополнительное пространство
    p.grot__text-big.text-big
      | Кроме того, что ГРОТ является уникальным спелеологическим объектом, это ещё и АРТ-ПРОСТРАНСТВО с работами
      | выборгских художников и старинными фотографиями Выборга
    ul.grot__list
      li.grot__item
         h3.grot__headline.headline.ff-garamond Дегустация
         p.grot__text.text
          | Можете попробовать В РОЗЛИВ крафтовые сорта сваренного в Выборге пива (эль, лагер, портер) и сделанного в
          | Выборге сидра, в холодное время вы можете попробовать сидр в виде горячего Глёга, и насладиться нашей
          | фирменной жемчужиной — ШОКОЛАДНЫМ СТАУТОМ
      li.grot__item
        h3.grot__headline.headline.ff-garamond Сувенирка
        p.grot__text.text
          | Таких сувениров как у нас (ШОКОЛАДНЫЙ ПУШКИН, КРЕЙЗИ ШОКО-ЗАЯЦ и т.д.) вы абсолютно точно не сможете
          | купить больше НИГДЕ в Выборге
      li.grot__item
        h3.grot__headline.headline.ff-garamond Шоколадный стаут
        p.grot__text.text
          | Не только крышесносный напиток, но и ЭКСКЛЮЗИВНЫЙ СУВЕНИР из Выборга
        p.grot__text.text
          | Под тару используем, как алюминиевые банки, так и стеклянные бутылки
      li.grot__item
        h3.grot__headline.grot__headline_logo.headline.ff-garamond
          | Выб
          picture.grot__headline-picture
            source(srcset="/images/grot/grot-1_31.webp 1x, /images/grot/grot-1_62.webp 2x", type="image/webp",  media="(max-width: 1240px)")
            source(srcset="/images/grot/grot-1_41.webp 1x, /images/grot/grot-1_62.webp 1.5x", type="image/webp")
            source(srcset="/images/grot/grot-1_41.png 1x, /images/grot/grot-1_62.png 1.5x")
            img.grot__headline-image(src="/images/grot/grot-1_41.png", alt="Грот - логотип", title="Грот - логотип", width="41", height="45")
          | ргер
        p.grot__text.text
          | Приготовленный по секретной рецептуре, становящийся уже символом Выборга бургер с нежнейшей
          | телячье-крольчачьей котлетой
    p.grot__contacts-headline.headline.ff-garamond
      | Ждем каждый день с 12&nbsp;до 21 часа
    p.grot__contacts-text.text
      | Грот находится слева от главных ворот Музея «Шоколадный Выборг»
    .grot__gallery
        picture.grot__picture(v-for="(image, i) in images", :key="i")
          source(:srcset="`${image.path}_288.webp 1x, ${image.path}_576.webp 2x`", type="image/webp", media="(max-width: 950px)")
          source(:srcset="`${image.path}_288.jpg 1x, ${image.path}_576.jpg 2x`", media="(max-width: 950px)")
          template(v-if="image.mod === 'small'")
            source(:srcset="`${image.path}_414.webp 1x, ${image.path}_828.webp 2x`", type="image/webp", media="(max-width: 1240px)")
            source(:srcset="`${image.path}_414.jpg 1x, ${image.path}_828.jpg 2x`", media="(max-width: 1240px)")
            source(:srcset="`${image.path}_576.webp 1x, ${image.path}_864.webp 1.5x`", type="image/webp")
            img.grot__image(
              :class="`grot__image_${image.mod}`",
              :src="`${image.path}_576.jpg`",
              :srcset="`${image.path}_1152.jpg 1.5x`",
              :alt="image.alt",
              :title="image.alt",
              loading="lazy",
              width="576",
              height="384"
            )
          template(v-else)
            source(:srcset="`${image.path}_852.webp 1x, ${image.path}_1704.webp 2x`", type="image/webp", media="(max-width: 1240px)")
            source(:srcset="`${image.path}_852.jpg 1x, ${image.path}_1704.jpg 2x`", media="(max-width: 1240px)")
            source(:srcset="`${image.path}_1176.webp 1x, ${image.path}_1764.webp 1.5x`", type="image/webp")
            img.grot__image(
              :class="`grot__image_${image.mod}`",
              :src="`${image.path}_1176.jpg`",
              :srcset="`${image.path}_1764.jpg 1.5x`",
              :alt="image.alt",
              :title="image.alt",
              loading="lazy",
              width="1176",
              height="784"
            )
</template>

<script>
import '@/assets/styles/components/grot.sass'

export default {
  name: 'TheGrot',
  data () {
    return {
      images: [
        { path: 'images/grot/grot-2', alt: 'Вход в арт-пространство «Грот» - фото', mod: 'big' },
        { path: 'images/grot/grot-3', alt: 'Арт-пространство «Грот» - фото', mod: 'small' },
        { path: 'images/grot/grot-4', alt: 'Дегустация шоколадного пива в Выборге - фото', mod: 'small' },
        { path: 'images/grot/grot-5', alt: 'Интерьер арт-пространства «Грот» - фото', mod: 'small' },
        { path: 'images/grot/grot-6', alt: 'Различные напитки в арт-пространстве «Грот» - фото', mod: 'small' },
        { path: 'images/grot/grot-7', alt: 'Шоколадное пиво в алюминиевых банках в Выборге - фото', mod: 'small' },
        { path: 'images/grot/grot-8', alt: 'Шоколадные угощения в арт-пространстве «Грот» - фото', mod: 'small' },
        { path: 'images/grot/grot-9', alt: 'Шоколад в виде Пушкина в Выборге - фото', mod: 'small' },
        { path: 'images/grot/grot-10', alt: 'Разные виды шоколадного пива в арт-пространстве «Грот» - фото', mod: 'small' },
        { path: 'images/grot/grot-11', alt: 'Горячий сидр Глёг в арт-пространстве «Грот» - фото', mod: 'small' },
        { path: 'images/grot/grot-12', alt: 'Шоколадный стаут в стеклянных бутылках в арт-пространстве «Грот» - фото', mod: 'small' }
      ]
    }
  }
}
</script>
